<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
          {{ title }}
        </h3>
        <div>
          <button class="btn btn-success btn-sm" @click="openModal()">
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="timeSlots"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.from") }}</th>
          <th>{{ $t("tables.to") }}</th>
          <th>{{ $t("tables.date") }}</th>
          <th>{{ $t("tables.action") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <td class="budget">
            {{ row.item.from_time }}
          </td>
          <td class="budget">
            {{ row.item.to_time }}
          </td>
          <td class="budget">
            {{
              moment(row.item.created_at).format(
                "ddd [-] DD[-]MM[-]YYYY [/] HH:mm a"
              )
            }}
          </td>
          <td class="budget text-right">
            <button class="btn btn-success btn-sm" @click="openModal(row.item)">
              <i class="fas fa-edit"></i>
            </button>
          </td>
        </template>
      </base-table>
    </div>
  </div>
  <!-- Modal -->
  <div
    v-if="formModal"
    class="modal fade show d-block"
    id="formModal"
    tabindex="-1"
    aria-labelledby="formModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="formModalLabel">
            {{ $t("form.time_slots_form") }}
          </h5>
          <button
            type="button"
            class="btn-secondary"
            @click="formModal = false"
          >
            x
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-lg-12 mb-3">
                <label class="form-control-label">{{ $t("form.from") }}</label>
                <input
                  type="time"
                  class="form-control"
                  v-model="updatedData.from_time"
                />
              </div>
              <div class="col-lg-12 mb-3">
                <label class="form-control-label">{{ $t("form.to") }}</label>
                <input
                  type="time"
                  class="form-control"
                  v-model="updatedData.to_time"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="formModal = false"
          >
            {{ $t("form.close") }}
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click="updateTimeSlots()"
          >
            {{ $t("form.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "time-slots-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      timeSlots: "",
      formModal: false,
      currentData: {},
      updatedData: {},
    };
  },
  created() {
    this.moment = moment;
    this.getLogs("admin/administration/timeSlots/index");
  },
  methods: {
    getLogs: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.timeSlots = response.data.data;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Open Modal
    openModal(timeSlotsData = null) {
      this.updatedData = {};
      this.formModal = true;
      if (timeSlotsData) {
        this.currentData = timeSlotsData;
        this.updatedData.from_time = moment(
          timeSlotsData.from_time,
          "h:mm A"
        ).format("HH:mm");
        this.updatedData.to_time = moment(
          timeSlotsData.to_time,
          "h:mm A"
        ).format("HH:mm");
        this.updatedData.id = timeSlotsData.id;
      }
    },
    // Create Or Update Time Slots
    updateTimeSlots: function () {
      this.loading = true;
      let formData = this.toFormData(this.updatedData);
      var url;
      if (this.updatedData.id) {
        url = "admin/administration/timeSlots/update";
      } else {
        url = "admin/administration/timeSlots/store";
      }
      axios
        .post(url, formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.$swal({
                position: "center",
                icon: "error",
                title: response.data.message,
                showConfirmButton: false,
              });
            } else {
              if (this.updatedData.id) {
                this.timeSlots.filter((item) => {
                  if (this.updatedData.id === item.id) {
                    item.from_time = this.updatedData.from_time;
                    item.to_time = this.updatedData.to_time;
                  }
                });
              } else {
                this.timeSlots.push(this.updatedData)
              }
              this.updatedData = {};
              this.formModal = false;
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Form Fields
    toFormData: function () {
      var fd = new FormData();
      if (this.updatedData["id"]) {
        fd.append("_method", "put");
        fd.append("id", this.updatedData["id"]);
      }
      fd.append("from_time", this.updatedData["from_time"]);
      fd.append("to_time", this.updatedData["to_time"]);
      return fd;
    },
  },
};
</script>
<style scoped>
.wrap {
  white-space: normal;
}
</style>
